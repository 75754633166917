import { collapseMenu } from "./components/collapse-menu";
import { externalLinks } from "./components/external-links";
import { generalFunctions } from "./components/general-functions";
import { Carouscroll } from "@zachleat/carouscroll";
import { bootstrapTooltips } from "./components/bootstrap-tooltips";

generalFunctions();

document.addEventListener('DOMContentLoaded', () => {
  /* eslint-disable no-new, no-restricted-syntax */
  collapseMenu();
  externalLinks();
  bootstrapTooltips();
  new Carouscroll();
});
